@import "sass/colors";

.profileContainer {
}

.profileInfo {
  font-size: 16px;
  margin-top: 50px;
  display: grid;
  grid-template-columns: 150px 50% 100px;

  width: 400px;
  grid-template-areas: 
  "title info button";

  gap: 10px 20px
}

.red {
  color: red;
}

.green {
  color: green;
}
@import "sass/colors";
@import "sass/mixins";

$input-height: 32px;

.s_mainContainer {
  width: fit-content;
}

.select {
  position: relative;

  input[type="search"] {
    height: $input-height;
    width: 100px;
    font-size: 14px;
    font-weight: 400;
    border: none;
    background: #1890ff;
    color: #fff;
    text-align: center;
    padding-left: 8px;
    border-radius: 1px;
    border: 1px solid transparent;
    cursor: pointer;
    &:hover {
      background: #40a9ff;
      border: 1px solid #40a9ff;
      transition: 0.4s;
    }
    &.s_input__visible,
    &:focus {
      outline: none;
      border: 1px solid #40a9ff;
      box-shadow: 0px 0px 2px 2px rgba(24, 143, 255, 0.2);
      transition: border 0.3s, box-shadow 0.3s;
    }
  }

  .saveButton {
    position: absolute;
    top: 340px;
    border-radius: 8px;
    right: 0px;
    visibility: hidden;
    opacity: 0;
    transition: 0.3s opacity, 0.3s visibility;
    z-index: 2;
    &.visible {
      display: flex;
      justify-content: left;
      flex-direction: column;
      visibility: visible;
      opacity: 1;
      transition: 0.3s opacity, 0.3s visibility;
    }
  }
}

.s_options {
  visibility: hidden;
  opacity: 0;
  transition: 0.3s opacity, 0.3s visibility;

  &.visible {
    display: flex;
    justify-content: left;
    flex-direction: column;
    visibility: visible;
    transition: 0.3s opacity, 0.3s visibility;
    opacity: 1;
  }

  position: absolute;
  background-color: white;
  overflow-y: scroll;
  border-radius: 8px;
  width: 550px;

  max-height: 300px;
  top: $input-height + 3px;
  z-index: 2;
  box-shadow: 1px 0px 6px #8a8a8a;

  @include scrollbar($blue);

  .option_item {
    margin-bottom: 1px;
    position: relative;
    min-height: 40px;
    &.item__active {
      background-color: rgba(97, 179, 255, 0.342);
      &:hover {
        background-color: rgba(77, 169, 255, 0.35);
        cursor: pointer;
      }
    }
    &:hover {
      background-color: rgb(228, 228, 228);
      cursor: pointer;
    }
  }
  .option_label {
    margin-left: 5px;
    font-size: 18px;
    margin-bottom: 2px;
  }
}

.dropArea {
  color: black !important;
  background: white !important;
  position: relative;

  &::before {
    content: "Оставить здесь";
    color: black;
    font-size: 18px;
    width: 100%;
    height: 100%;
    border: 2px dashed hsla(197, 100%, 50%, 0.445);
    border-radius: 3px;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .option_item {
    &.item__active {
      background-color: transparent;
    }
    span {
      display: none;
    }
  }
}

.modal {
  margin: 20px 5px;

  input {
    margin-bottom: 20px;
  }
  .modal_button {
    button {
      margin-right: 10px;
    }
  }
}
.container {
  display: flex;
  align-items: center;
  position: relative;
}

.indicator {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 5px;
  position: absolute;
  left: -17px;
}

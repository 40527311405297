.ant-form-item-extra {
  min-height: 0;
  position  : absolute;
  bottom    : -18px;
  left      : 2px;
}

.submit-button {
  outline      : none;
  background   : transparent;
  border       : 1px solid blue;
  border-radius: 20px;
  height       : 30px;
  box-shadow   : 3px 1px 8px -2px blue;
  width        : 150px;
  cursor       : pointer;
  transition   : 0.3s;
  margin-left  : 470px;

  &:hover {
    transition: 0.3s;
    box-shadow: 4px 2px 10px -1px blue;
  }
}

.ant-upload-list-item-card-actions-btn span svg {
  font-size: 20px;
}

.spinnerContainer {
  margin       : 0;
  width        : 100vw;
  height       : 100vh;
  opacity      : 1;
  padding      : 30px 50px;
  text-align   : center;
  background   : rgba(150, 139, 139, 0.397);
  border-radius: 4px;
}

.spinner {
  margin-top: 30vh;

  span {
    font-size: 40px;
  }
}
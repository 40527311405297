.loginContainer {
  height: 100%;
  display: flex;
  justify-content: center;
  margin-top: 100px;
}

.form {
  width: 400px;
  max-height: 300px;
}
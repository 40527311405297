@mixin scrollbar($color) {
  scrollbar-color: $color transparent;
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    width           : 6px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    border-radius   : 6px;
    background-color: $color;
  }
}

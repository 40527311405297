.container {
  position: relative;
}

.formItem {
  margin-right: 20px;
}
.checkBox {
  margin-top: 35px;
}
.rowSelector {
  margin-left: 10px;
}

.buttonContainer {
  display: flex;
}
.dropDown {
  height: 40px;
  font-size: 18px;
}
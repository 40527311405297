.title {
  margin-bottom: 0 !important;
}
.topContainer {
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;
}
.tableButtons {
  display: flex;
  justify-content: flex-end;
}
.formContainer {
  display: flex;
  justify-content: center;
  padding: 20px;
  .formRow {
    justify-content: space-between;
  }

  .formCol {
    width: 47%;
  }

  .inputMask {
    height: 32px;
    width: 204px;
    font-size: 14px;
    border: 1px solid #d9d9d9;
    padding: 4px 11px;
    border-radius: 2px;
    transition: all 0.3s;

    &:hover {
      transition: all 0.3s;
      border-width: 1px;
      border-color: #1890ff;
    }
    &:focus-visible {
      transition: all 0.3s;
      outline: none;
      border: solid 1px #2d99ff;
      box-shadow: 0 0px 1px 2px #d5ebff;
    }
  }
}

.icons {
  font-size: 25px;

  &:first-child {
    margin-right: 20px;
  }
}

.row {
  background-color: #fff;
  &:hover {
    cursor: pointer;
  }
}

.table {
  :global(.ant-table-cell) {
    background: #fff;
  }
}

.highlight {
  background-color: #ff9632;
}

.metaInfo {
  position: relative;

  font-size            : 16px;
  margin-top           : 50px;
  display              : grid;
  grid-template-columns: 150px max-content;

  width: 100%;
  grid-template-areas:
    "title info";

  gap: 1px 10px
}

.copyRow {
  background-color: rgb(245, 245, 245);
  padding         : 5px;
  cursor          : pointer;
  border-radius   : 5px;

  -webkit-touch-callout: none;
  -webkit-user-select  : none;
  -khtml-user-select   : none;
  -moz-user-select     : none;
  -ms-user-select      : none;
  user-select          : none;
}

.text {
  font-size  : 16px;
  color      : black;
  font-weight: 400;
  position   : absolute;
  top        : 10%;
  left       : 34%;
  display    : none;
  z-index    : 100000;
  opacity    : 0;
}

.visibleText {
  display: block;
  opacity: 1;
}

.stringMeta {
  padding  : 20px;
  font-size: 16px;

  .titleMeta {
    font-size  : 18px;
    font-weight: 500;

  }
}

.formContainer {
  display        : flex;
  justify-content: center;
  flex-direction : column;
  padding        : 20px;
}

.photoContainer {
  display        : flex;
  justify-content: center;
  align-items    : center;
  flex-direction : row;
  margin-bottom  : 20px;
}

.imagePreview {
  margin-right: 10px;
}

.photoContainer {
  display        : flex;
  justify-content: center;
  align-items    : center;
  flex-direction : row;
  margin-bottom  : 20px;
}

.downloadRow {
  display        : flex;
  position       : relative;
  justify-content: flex-start;
  align-items    : center;
  flex-direction : row;
  margin-bottom  : 5px;

  & .fileName {
    font-size: 18px;
  }
}

.downloadIcon {
  position  : absolute;
  bottom    : 2px;
  left      : 135px;
  font-size : 26px;
  color     : #1890ff;
  transition: 0.3s all;

  &:hover {
    cursor    : pointer;
    transition: 0.3s all;
    font-size : 30px;
    left      : 134px;
  }
}
@import "sass/mixins";
@import "sass/colors";

.title {
  margin-bottom: 0 !important;
}
.topContainer {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  .applyButton {
    margin-left: 100px;
  }
}

.description {
  min-width: 500px;
  max-width: 800px;
  width: 100%;
}

.currentUser {
  display: flex;
  justify-content: flex-end;
}

.leftInfoContainer {
  margin-left: auto;
  ul {
    font-size: 16px;
    padding: 0;
    margin: 0;
    list-style: none;
  }
}

.linkAzur {
  font-weight: 600;
  color: #fff;
  font-size: 20px;
  font-weight: 700;
  background: linear-gradient(to right, #2393f5 10%, #0850af 50%, #2393f5 60%);
  background-size: auto auto;
  background-clip: border-box;
  background-size: 200% auto;
  color: #fff;
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textclip 1.5s linear infinite;
  display: inline-block;
  transition: all 0.2s;

  &:hover {
    transform: scale(1.05);
    transition: all 0.2s;
  }
}

@keyframes textclip {
  to {
    background-position: 200% center;
  }
}

.infoCard {
  position: relative;
}

.editButton {
  position: absolute;
  bottom: 9%;
  right: 5%;
  font-size: 32px;
  color: #1890ff;
  cursor: pointer;
}
.row {
  cursor: pointer;
}

.clientCardList {
  display: flex;
  flex-direction: column;
  // grid-template-areas: 'title info';
  // grid-template-columns: 200px auto;
  list-style: none;
  margin: 0;
  font-size: 16px;
}

.clientCardListItem {
  display: grid;
  grid-gap: 0px 5px;
  grid-template-areas: "title info";
  grid-template-columns: 330px auto;
  color: #777777;
  margin-bottom: 2px;

  p {
    margin: 0px 0px 0px 0px;
    color: black;
    max-width: 550px;
    display: flex;
    align-items: center;
  }
}
.formContainer {
  display: flex;
  justify-content: center;
  padding: 20px;
}
.decisionContainer {
  display: flex;
  flex-direction: column;
  width: 30%;
}

.decisionSelect {
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
}

.singleSelect {
  width: 300px;
  margin-right: 10px;
}

.sysDecisionContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.commentsContainer {
  max-height: 330px;
  overflow-y: scroll;
  @include scrollbar($gray);
}

.commentCard {
  width: 500px;
}

.userAvatar {
  font-size: 29px;
}

.commentTitle {
  display: flex;
  align-items: center;

  .commentName {
    font-size: 21px;
    margin: 0;
  }
  .commentTime {
    font-size: 14px;
    margin: 5px 0 0 10px;
    color: rgb(153, 152, 152);
  }
}

.comment {
  color: black;
  font-size: 16px;
}

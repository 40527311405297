.react-datepicker {
  width: max-content;
}

html {
  &::-webkit-scrollbar {
    width: 6px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 6px;
    background-color: #188fff9a;
  }
}

.icon {
  line-height: 20px !important;

  :global(.anticon-tags) {
    line-height: initial !important;
  }
  svg {
    fill: black;
    font-size: 25px;
  }
}

@import "sass/colors.scss";

.options_container {
  .list {
    display   : flex;
    list-style: none;

    .list_position {
      position: relative;

      .item_delete {
        position : absolute;
        cursor   : pointer;
        top      : 8px;
        right    : 18px;
        color    : rgb(255, 49, 49);
        font-size: 20px;
      }

      .list_item {
        position       : relative;
        margin-right   : 15px;
        border         : 1px solid $blue;
        display        : flex;
        min-width      : 70px;
        justify-content: center;
        align-items    : center;
        border-radius  : 5px;

        &:hover {
          cursor    : default;
          box-shadow: 1px 1px 6px $blue;
        }

        span {
          font-size    : 20px;
          margin       : 0px 10px;
          padding-right: 15px;
          color        : $blue;
        }
      }
    }
  }
}

.checkbox_container {
  display        : flex;
  flex-direction : column;
  justify-content: flex-start;

  label {
    margin-left: 0 !important;
  }
}
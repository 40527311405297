.title {
  margin-bottom: 0 !important;
}
.topContainer {
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;
}
.tableButtons {
  display: flex;
  justify-content: center;
}
.formContainer {
  display: flex;
  justify-content: center;
  padding: 20px;
}

.icons {
  font-size: 25px;

  &:first-child {
    margin-right: 20px;
  }
}


.test {
  width: 200px;
  height: 200px;
  background-color: black;
  position: relative;

  .test_center {
    position: absolute;
    width: 50px;
    height: 50px;
    background-color: blue;
    top: 45%;
    left: 45%;
    z-index: 1000;
  }
}
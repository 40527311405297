.table_acception_color {
  background-color: #fce5cd !important;
}

.table_analys_color {
  background-color: #fff2cc !important;
}

.table_decision_color {
  background-color: #d0e0e3 !important;
}

.table_system_color {
  background-color: #d9ead3 !important;
}

.table_users_color {
  background-color: #f4cbcc !important;
}

.row {
  cursor: pointer;
}

.indicator {
  width        : 30px;
  height       : 30px;
  border-radius: 50%;
}

.mb {
  margin-bottom: 30px !important;
}

.idContainer {
  position: relative;

  .idMail {
    position : absolute;
    top      : -15px;
    left     : 1px;
    font-size: 13px;
    color    : #1890ff;
  }
}

.phoneContainer {
  position: relative;

  .vipIcon {
    position : absolute;
    left     : 40%;
    font-size: 18px;
    top      : -70%;
    z-index  : 10000;
  }
}


.clientPhone {
  min-width: 130px;
}

.date {
  min-width: 105px;
}

.channelIcon {
  height: 25px;
  width : 25px;
}

.statusContainer {
  display    : flex;
  align-items: center;
}

.problemIcon {
  display        : flex;
  justify-content: center;
  width          : 25px;
  height         : 25px;
  align-items    : center;
  font-size      : 20px;
  margin-left    : 10px;

  svg {
    fill: rgb(255, 174, 0);
  }
}

.pagination {
  margin-top: 20px;
}

.description {
  min-width: 500px;
  max-width: 800px;
  width    : 100%;
}

.formItem {
  margin-right: 20px;
}


.supportAnswer {
  font-size: 16px;
  color: #adadad;
  margin-bottom: 5px;
  margin-top: 15px;
}
.selfAnswer {
  max-width: 940px;
}

.resultInfo {
  padding: 0 25px;
}

.spinnerContainer {
  margin: 0;
  width: 100vw;
  height: 100vh;
  opacity: 1;
  padding: 30px 50px;
  text-align: center;
  border-radius: 4px;
}

.spinner {
  margin-top: 30vh;
  span {
    font-size: 40px;
  }
}
.title {
  margin-bottom: 0 !important;
}
.topContainer {
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;
}
.tableButtons {
  display: flex;
  justify-content: center;
}
.formContainer {
  display: flex;
  justify-content: center;
  padding: 20px;
}

.icons {
  font-size: 25px;

  &:first-child {
    margin-right: 20px;
  }
}

.formItem {
  margin-right: 20px;
}

.row {
  cursor: pointer;
}

.pagination {
  margin-top: 20px;
}
.description {
  min-width: 500px;
  max-width: 800px;
  width: 100%;
}

.warningText {
  color: red;
  font-weight: 700;
}